export default [
  {
    header: 'Data-uitwisseling',
    icon: 'ArchiveIcon',
    action: 'read',
    resource: 'data-exchange',
    children: [
      {
        title: 'Import',
        route: 'app-data-import',
        icon: 'DownloadIcon',
        action: 'read',
        resource: 'data-exchange',
      },
      {
        title: 'Export',
        route: 'app-data-export',
        icon: 'UploadIcon',
        action: 'read',
        resource: 'data-exchange',
      },
    ],
  },
]
