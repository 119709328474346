export default [
  {
    title: 'Dashboards',
    header: 'Dashboards',
    icon: 'HomeIcon',
    route: 'app-dashboard',
    action: 'read',
    resource: 'dashboard',
  },
]
