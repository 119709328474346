export default [
  {
    header: 'Instellingen',
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'account',
    children: [
      {
        title: 'Account',
        route: 'app-settings-account',
        icon: 'UserIcon',
        action: 'read',
        resource: 'account',
      },
      {
        title: 'Gebruikers',
        route: 'app-users-list',
        icon: 'UserPlusIcon',
        action: 'read',
        resource: 'users',
      },
      // {
      //   title: 'Apps',
      //   route: 'app-apps',
      //   icon: 'CloudIcon',
      //   action: 'read',
      //   resource: 'apps',
      // },
    ],
  },
]
